



























































































































import { Component, Inject, Vue } from 'vue-property-decorator';
import PageHeader1 from '@/components/PageHeader1.vue';
import { ApiFacade } from '@/services/ApiFacade';
import { User } from '@/models/entities/User';
import { AppStore } from '@/store/types/AppStore';
import { UserRoleIds } from '@/types/UserRoleIds';
import Subtitle from '@/components/Subtitle.vue';
import NewUserForm from '@/views/users/components/NewUserForm.vue';
import { HasValidateMethod } from '@/types/VForm';
import FindUser from '@/views/users/components/FindUser.vue';
import UserRights from '@/views/users/components/UserRights.vue';

interface AddUserView {
  $refs: {
    userForm: HasValidateMethod & Vue;
  };
}

@Component({
  components: {
    FindUser,
    NewUserForm,
    Subtitle,
    PageHeader1,
    UserRights,
  },
})
class AddUserView extends Vue {
  @Inject() apiFacade!: ApiFacade;
  @Inject() appStore!: AppStore;
  email: string = '';
  user: User | null = null;
  selectedRoles: { [polygonId: number]: UserRoleIds | 'none' } = {};

  isSending: boolean = false;
  isUserWasCreated: boolean = false;
  isUserWasUpdated: boolean = false;
  isUserFormValid: boolean = false;
  error: Error | string | null = null;

  async mounted() {
    this.appStore.actions.fetchAvailablePolygons();
  }

  get errorMessage() {
    if (!this.error) {
      return '';
    }
    if (typeof this.error === 'string') {
      return this.error;
    }
    return this.error.message || '';
  }

  get isCreateUserPossible() {
    return this.isUserFormValid;
  }

  handleFindUserResult(user: User | null) {
    this.user = user;
    this.isUserWasCreated = false;
    this.isUserWasUpdated = false;
  }

  get availablePolygons() {
    return this.appStore.getters.availablePolygons;
  }

  onUserFormValidation(validation: boolean) {
    this.isUserFormValid = Boolean(validation);
  }

  async createUser() {
    if (!this.user) {
      return;
    }
    if (!this.user.userId && !this.isUserFormValid) {
      return;
    }

    this.isSending = true;
    try {
      if (this.user.userId) {
        await this.apiFacade.updateUser(this.user);
        this.isUserWasUpdated = true;
      } else {
        const updatedUser = await this.apiFacade.createNewUser(this.user);

        if (updatedUser.userId) {
          this.isUserWasCreated = true;
        }
      }
    } catch (error) {
      this.error = error;
    } finally {
      this.isSending = false;
    }
  }
}

export default AddUserView;
